<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M251.11 39.89C257.63 46.4101 257.63 56.9812 251.11 63.5013L98.0665 216.545C94.9354 219.676 90.6888 221.435 86.2609 221.435C81.8329 221.435 77.5863 219.676 74.4553 216.545L4.89004 146.98C-1.63001 140.459 -1.63001 129.888 4.89004 123.368C11.4101 116.848 21.9812 116.848 28.5013 123.368L86.2609 181.128L227.499 39.89C234.019 33.37 244.59 33.37 251.11 39.89Z"
      fill="currentColor"
    />
  </svg>
</template>
